import React from "react";
import Layout from "../components/layout";
import MentionsLegales from "../components/mentionslegales";

const MentionsLegalesPage = () => (
  <Layout isMain={false}>
    <MentionsLegales />
  </Layout>
);

export default MentionsLegalesPage;
